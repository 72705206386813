.solution5 {
  width: 100%;
  /* background-attachment: fixed; */
  z-index: 20;
  background-size: cover;
  background-position: 50% 0%;
  background-repeat: no-repeat;
}
.emergency-banner-bg {
  background-image: url("../img/staticImages/emergency-crowdfunding-images/emergency-banner.png");
}
.medical-banner-bg {
  background-image: url("../img/staticImages/medical-crowdfunding-images/medical-banner.png");
}
.education-banner-bg {
  background-image: url("../img/staticImages/educational-crowdfunding-images/educational-banner.png");
  /* background-size: 100% 100%;                      
    background-repeat:   no-repeat;
    background-position: center center;  */
}
.sports-banner-bg {
  background-image: url("../img/staticImages/sports-crowdfunding-images/sport-banner.png");
}

.child-banner-bg {
  background-image: url("../img/staticImages/child-crowdfunding-images/child-banner.png");
}
.animal-banner-bg {
  background-image: url("../img/staticImages/animal-crowdfunding-images/animal-banner.png");
}
.hunger-free-banner-bg {
  background-image: url("../img/staticImages/hunger-free-funding/hunger-free-banner.png");
}
.memorial-banner-bg {
  background-image: url("../img/staticImages/memorial-crowdfunding-images/memorial-banner.png");
}
/* .textSectionMF {
  display: flex;
  justify-content: center;
  height: 70vh;
  flex-direction: column;
  opacity: 0.9;
  direction: rtl;
} */
.formSectionMF .form-control {
  height: 48px;
  font-size: 14px;
  border-radius: 8px;
}
.heading {
  color: white;
  /* margin-top: 230px; */
}
.heading-p {
  color: white;
  font-size: 18px;
}
.maindiv-form {
  display: flex;
  max-width: 40%;
}
@media (max-width: 1000px) {
  .maindiv-form {
    max-width: 100%;
  }
  .fund-link-res {
    width: 100%;
  }
}
@media (min-width: 575px) {
  .maindiv-form {
    display: flex;
    /* max-width: 40%; */
  }
  .text4 {
    color: white;
    border-bottom-style: solid;
    border-color: #ffee00;
    border-width: 4px;
    font-size: 20px;
    width: 55%;
  }
}
@media (max-width: 575px) {
  .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    margin: 10px;
    text-align: center;
  }

  .img-div-1 img,
  .img-div-2 img {
    width: 100%;
  }
  .solution5 {
    height: auto;
  }
  .maindiv-form {
    display: flex;
  }

  .info-title {
    font-weight: 400;
    font-size: 26px;
    line-height: 33px;
    color: #444;
    margin-bottom: 10px;
    text-align: center;
  }
  .desc1 {
    text-align: center;
    font-size: 15px;
    text-align: justify;
  }

  .med-title {
    margin-top: 0;
    padding-top: 12px;
    font-size: 32px;
  }
  .img-div {
    display: flex;
    align-items: center;
  }

  .img-div {
    width: 100%;
  }

  .offerSection {
    padding: 0;
  }

  .high-title {
    width: 100%;
    font-size: 30px;
  }

  .section--bg {
    padding: 0;
  }

  .box-wrapper {
    max-width: 100%;
  }

  .testimonialSection {
    padding-top: 0;
  }

  .title-info {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 18px;
  }

  .case-content {
    font-size: 15px;
  }

  .card__container {
    width: 100%;
    margin: 0;
    padding: 12px;
  }
  .card__wrapper {
    display: flex;
    width: 100%;
  }

  .card__wrapperImg {
    width: 30%;
    object-fit: contain;
  }

  .card__wrapperContent {
    width: 70%;
    padding: 0;
  }

  .card__wrapperContent .card-body {
    padding: 12px !important;
    font-size: 11px;
  }

  .card-body h5 {
    font-size: 17px;
  }

  .mobileWidth-100 {
    width: 100%;
  }
  .text4 {
    color: white;
    border-bottom-style: solid;
    border-color: #ffee00;
    border-width: 4px;
    font-size: 20px;
    width: 67%;
    text-align: center;
    margin-left: 70px;
    justify-content: center;
  }
}

.spam {
  size: 15px;
}

.form-div {
  margin-bottom: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0 6px 40px #0000001a;
  z-index: 1;
  width: 600px;
}
.heading-form {
  text-align: center;
  color: black;
  font-size: 26px;
  line-height: 33px;
}
.form-img {
  display: block;
  width: 40%;
  margin: 50px auto;
}

.form-p {
  font-weight: 700;
  line-height: 20px;
  color: var(--primary);
  text-align: center;
  margin-bottom: 10px;
  font-size: 14px;
}
.mid-div {
  width: 100%;
}
.after-mid {
  max-width: 1140px;
}
.after-after {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.header {
  align-items: center;
}
.med-title {
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  color: #444;
  margin-bottom: 0.5rem;
  text-align: center;
}
.desc {
  font-size: 17px;
  line-height: 27px;
  color: #999;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fq-div {
  text-align: justify;
}

.info-title {
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  color: #444;
  margin-bottom: 10px;
}
.desc1 {
  font-size: 16px;
  line-height: 27px;
  /* color: #999; */
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
}
.img-div-1 {
  text-align: end;
}

.img-div-2 {
  text-align: start;
}

.high-title {
  margin: 0 auto;
  font-weight: 400;
  font-size: 40px;
  color: #444;
  text-align: center;
}
.title-background {
  margin: 0 auto;
  font-weight: 600;
  font-size: 40px;
  color: #fff;
  text-align: center;
}
.divider {
  border-bottom: 3px solid #ffee00;
  width: 100px;
  margin: 20px auto;
}
.offerSection {
  width: 100%;
  justify-content: center;
}
.divider-sm {
  width: 40px;
  border-bottom: 3px solid #ffee00;
  margin: 20px auto;
}
.title {
  white-space: initial;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  text-transform: uppercase;
  color: white;
  min-height: 45px;
}
.hide {
  visibility: hidden;
  margin: 10px 0;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-decoration: underline;
  color: white;
  justify-content: center;
}

.show-s {
  padding: 20px;
  background-color: var(--primary);
  border-radius: 4px;
  align-items: center;
  transition: background-color 500ms linear;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
}
.title:hover + .hide {
  visibility: visible;
}
.heading-card {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  color: var(--primary);
}
.p-card {
  font-size: 13px;
  margin-bottom: 10px;
  text-align: center;
  color: #444;
}
.button-c {
  box-sizing: border-box;
  width: 320px;
  height: 44px;
  background-color: var(--primary);
  color: white;
  font-size: 15px;
  box-shadow: 0 3px 1px -2px #0003;
  border: none;
  border-radius: 5px;
  margin-bottom: 5px;
}
.info-title-new {
  font-weight: 400;
  font-size: 40px;
  line-height: 120%;
  color: #444;
  margin: 20px auto;
}
.high-cost {
  background-position: 0 0;
  height: 70px;
  width: 70px;
  text-align: center;
  margin: 0 auto;
  background-repeat: no-repeat;
}
.text-heading {
  font-weight: 700;
  font-size: 19px;
  line-height: 20px;
  color: #444;
  margin: 10px auto;
  width: 66%;
}
.main-div-c {
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 40px;
}
.text-desc {
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  /* color: #00000080; */
  color: black;
  margin: 10px auto;
  width: 60%;
  justify-content: center;
}
.flex-center {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.load-more {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  text-transform: capitalize;
  color: var(--primary);
  cursor: pointer;
  text-align: center;
}
.banner-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.title-info {
  font-size: 36px;
  font-weight: 700;
  line-height: 45px;
  color: var(--primary);
}
.case-content {
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  max-width: 560px;
}
.mat-button-base {
  margin-top: 20px;
  width: 249px;
  height: 54px;
  background: var(--primary);
  border-radius: 4.19708px;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  border: none;
}
.content-wrapper {
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.content-box {
  background: rgba(43, 91, 13, 0.03);
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin: 15px 20px;
  height: 142px;
  padding: 20px;
}
.free-icon {
  height: 40px;
  width: 40px;
  background-position: 560px 0;
}
.content-d {
  font-size: 17px;
  line-height: 27px;
  color: #444;
  width: 75%;
  margin-left: 20px;
}
.heading-section {
  line-height: 34px !important;
}
.image-section {
  width: 100%;
  border-radius: 10px;
  max-height: 350px;
  object-fit: cover;
}

.image-style {
  background-position: 198px -13px;
  height: 80px;
  width: 80px;
  text-align: center;
  margin: 0 auto 15%;
  /* border: 1px solid #eeeeee; */
  box-sizing: border-box;
  /* background-color: #fff; */
  border-radius: 20px;
  background-repeat: repeat;
}
/* .header-style{
  line-height: 30px;
} */
.image-style {
  background-position: 198px -13px;
  height: 70px;
  width: 70px;
  text-align: center;
  margin: 0 auto 15%;
  /* border: 1px solid #eeeeee; */
  box-sizing: border-box;
  /* background-color: #fff; */
  border-radius: 20px;
  background-repeat: repeat;
}
.error-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);
}
