/*
Project Name: Building bridges for islam
CSS Author: Ammar Habib
Email : ammar.habib.mughal@gmail.com;
Author Designation: Sr.Front End Developer
Framework Used: React-Bootstrap
Version: 1.0

1-Variables
2-Generic
3-Base Elements
4-Layout Classes
5-Components
6-IDS and !important
*/

/*fons*/
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Roboto&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

:root {
  --primary: #002866;
  --secondary: #f9cb40;
  --success: #777;
  --info: #0dcaf0;
  --warning: #ffc107;
  --lightYellow: #fac877;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #000;
  --white: #ffffff;
  --primary-rgb: 0, 40, 102;
  --secondary-rgb: 255, 238, 0;
  --success-rgb: 77, 105, 149;
  --info-rgb: 13, 202, 240;
  --warning-rgb: 255, 193, 7;
  --danger-rgb: 220, 53, 69;
  --light-rgb: 248, 249, 250;
  --dark-rgb: 33, 37, 41;
  --white-rgb: 255, 255, 255;
  --font-primary: "Montserrat", sans-serif;
  /* --font-secondary: "Roboto", sans-serif; */
}

body {
  font-family: var(--font-primary);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0;
  color: var(--dark);
  text-transform: none;
}

.wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main {
  flex: 1 1 auto;
}

.btn,
.btn:hover {
  font-weight: 600 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: var(--primary);
  font-family: var(--font-primary);
  font-weight: 700;
}

h1,
.h1 {
  font-size: 36px;
}

h2,
.h2 {
  font-size: 34px;
  /* line-height: 50px; */
}

h3,
.h3 {
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: 0;
}

.swal-text {
  color: black;
  line-height: 25px;
}

.bg-primary {
  background: var(--primary);
}

.bg-secondary {
  background: var(--secondary);
}

/*buttons*/
/* .btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: var(--font-primary);
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 700;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 3px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 3px;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-check:focus + .btn,
.btn:focus {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: none;
}

.btn-primary {
  --bs-btn-color: var(--white);
  --bs-btn-bg: var(--primary);
  --bs-btn-border-color: var(--primary);
  --bs-btn-hover-color: var(--primary);
  --bs-btn-hover-bg: var(--secondary);
  --bs-btn-hover-border-color: var(--secondary);
  --bs-btn-focus-shadow-rgb: var(--secondary-rgb);
  --bs-btn-active-color: var(--white);
  --bs-btn-active-bg: var(--secondary);
  --bs-btn-active-border-color: var(--secondary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--white);
  --bs-btn-disabled-bg: rgba(var(--primary), 0.125);
  --bs-btn-disabled-border-color: rgba(var(--primary), 0.125);
}

.btn-outline-primary {
  --bs-btn-color: var(--primary);
  --bs-btn-border-color: var(--primary);
  --bs-btn-hover-color: var(--white);
  --bs-btn-hover-bg: var(--primary);
  --bs-btn-hover-border-color: var(--primary);
  --bs-btn-focus-shadow-rgb: var(--primary-rgb);
  --bs-btn-active-color: var(--white);
  --bs-btn-active-bg: var(--primary);
  --bs-btn-active-border-color: var(--primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--primary);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--primary);
  --bs-gradient: none;
}

.btn-secondary {
  --bs-btn-color: var(--primary);
  --bs-btn-bg: var(--secondary);
  --bs-btn-border-color: var(--secondary);
  --bs-btn-hover-color: var(--white);
  --bs-btn-hover-bg: var(--primary);
  --bs-btn-hover-border-color: var(--primary);
  --bs-btn-focus-shadow-rgb: var(--primary);
  --bs-btn-active-color: var(--white);
  --bs-btn-active-bg: var(--primary);
  --bs-btn-active-border-color: var(--primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--secondary);
  --bs-btn-disabled-bg: rgba(var(--secondary-rgb), 0.125);
  --bs-btn-disabled-border-color: rgba(var(--secondary-rgb), 0.125);
}

.btn-group-lg > .btn,
.btn-lg {
  --bs-btn-padding-y: 0.96rem;
  --bs-btn-padding-x: 2.2rem;
} */

.sectionPadding {
  padding: 100px 0;
}

.section {
  position: relative;
  padding: 60px 0;
}

.section--bg {
  background-size: cover !important;
}

.sectionEffect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-transform-origin: 20% 40%;
  -ms-transform-origin: 20% 40%;
  transform-origin: 20% 40%;
}

.sectionEffect__layer {
  width: 100%;
  height: 100%;
  --scale: 1.23388;
  transform: scale(var(--scale));
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  -o-transition-property: transform, opacity;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0, 0.33, 0.07, 1.03);
  -o-transition-timing-function: cubic-bezier(0, 0.33, 0.07, 1.03);
  transition-timing-function: cubic-bezier(0, 0.33, 0.07, 1.03);
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
}

.section__overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(var(--primary-rgb), 0.7);
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.wh__60 {
  height: 60px;
  width: 60px;
}

.section__overlay-content {
  position: relative;
  z-index: 2;
}

/*form inputs*/

.input-group-text {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 2px;
}

.form-control {
  background-color: #fdfdfd;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
  line-height: 24px;
  padding: 16px 20px;
  border-radius: 8px;
  color: var(--dark);
}

.form-control:focus {
  color: var(--dark);
  background-color: #fdfdfd;
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: none;
}

::placeholder {
  color: rgba(51, 51, 51, 0.5);
  opacity: 1;
}

:-ms-input-placeholder {
  color: rgba(51, 51, 51, 0.5);
}

::-ms-input-placeholder {
  color: rgba(51, 51, 51, 0.5);
}

/*header*/

.navbar {
  background-color: var(--white);
  box-shadow: 0 0 10px rgb(0 0 0 / 30%);
  z-index: 99;
}

.nav-link {
  display: block;
  padding: 8px 15px;
  font-size: 16px;
  font-weight: 600;
  color: var(--primary);
  text-decoration: none;
  font-family: var(--font-primary);
}

.nav-link.btn-primary {
  color: var(--secondary);
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active:hover,
.navbar-nav .nav-link:hover {
  color: var(--primary);
  background-color: var(--secondary);
}

/*banner section*/

.banner {
  background-size: cover !important;
  padding: 0px 0 0px 0;
}

.banner h2 {
  font-size: 24px;
  font-weight: 500;
  width: 70%;
  margin: 0 auto;
  line-height: 1.25em;
}

.hero-section-bg {
  height: 625px;
  object-fit: fill;
}

.banner-title {
  margin-top: 170px !important;
  /* line-height: 70px; */
  letter-spacing: 3px;
  font-size: 70px !important;
  /* font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important; */
}

.banner-title-bottom {
  /* margin-top: 170px !important; */
  /* line-height: 70px; */
  letter-spacing: 1px;
  font-size: 45px !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
}

.banner p {
  font-size: 22px;
  font-weight: 500;
  margin: 0 auto;
}

.hero-section {
  /* position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%); */
  margin-left: 6%;
  /* text-shadow: 5px 5px 5px #000; */
}

/*counterSectio*/
.counterSection {
  padding: 60px 0px 60px 0px;
  background-size: cover !important;
}

.counterSection .container {
  max-width: 1000px;
}

.counters {
  text-align: right;
  padding-right: 75px;
}

.counter__number {
  color: var(--primary);
  font-size: 42px;
  font-weight: 700;
  line-height: 1em;
}

.counter__title {
  color: var(--primary);
  font-size: 18px;
  line-height: 1.25em;
  font-weight: 400;
}

.counter__divider {
  width: 100px;
  opacity: 1;
  margin: 2.5rem 0 2.5rem auto;
}

/* Become a sponsor */
.become__sponsor {
  z-index: 98;
}

.become__sponsor .become__sponsor--content {
  padding: 50px 30px 50px 30px;
}

.become__sponsor .container {
  background-color: var(--secondary);
  margin-top: -165px;
}

.become__sponsor .badge--text {
  background-color: var(--primary);
  color: var(--secondary);
  border-radius: 4px;
}

.become__sponsor--content .col-md-5 {
  padding: 15px;
}

.become__sponsor.become__sponsor--image {
  background-image: url("../img/world-map.png");
  width: 100%;
}

/*helping section*/
.helpingSection {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 70px 0;
  background-size: cover !important;
}

.helpingSection p:not(.helpingItem__text) {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1.2px;
  margin-bottom: 24px;
}

.helpingItem {
  padding: 20px;
  /* background-color: var(--primary); */
  border-radius: 4px;
  /* border: 3px solid var(--primary); */
  align-items: center;
  -webkit-transition: background-color 500ms linear;
  -moz-transition: background-color 500ms linear;
  -o-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
  /* margin-bottom: 20px; */
  height: 100%;
  /* text-align: center; */
  display: flex;
  /* flex-direction: column; */
  justify-content: space-evenly;
}

.helpingItemView {
  padding: 20px;
  background-color: #3c5275;
  border-radius: 4px;
  /* border: 3px solid var(--primary); */
  align-items: center;
  -webkit-transition: background-color 500ms linear;
  -moz-transition: background-color 500ms linear;
  -o-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
  /* margin-bottom: 20px; */
  height: 100%;
  /* text-align: center; */
  display: flex;
  /* flex-direction: column; */
  justify-content: space-evenly;
}

.what__we-offer {
  /* border: 3px solid var(--primary); */
  margin-bottom: 20px;
  text-align: center;
  flex-direction: column;
}

.helpingItem:hover {
  color: white !important;
}

.helpingItem__icon {
  /* display: inline-flex; */
  /* width: 100px; */
  /* height: 100px; */
  align-items: center;
  justify-content: center;
  /* margin-right: 16px; */
  background: var(--white);
  border-radius: 50%;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
  -webkit-box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.helpingItem__icon svg {
  width: 50px;
  height: auto;
  margin-top: -2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.helpingItem__heading a {
  color: var(--white);
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 12px;
  text-decoration: none;
}

.helpingItem__text {
  color: var(--white) !important;
  margin-bottom: 0;
}

/*.helpingItem:hover .helpingItem__icon*/
/*{*/
/*    background: var(--white);*/
/*}*/

.helpingItem:hover .helpingItem__heading a,
.helpingItem:hover .helpingItem__text {
  color: var(--primary);
}

.helpingItem--outline {
  background-color: transparent;
  border-color: var(--primary);
}

.helpingItem--outline:hover {
  background-color: var(--primary);
  /*border-color:var(--secondary) ;*/
}

.helpingItem--outline .helpingItem__icon {
  background: var(--secondary);
}

.helpingItem--outline .helpingItem__heading,
.helpingItem--outline .helpingItem__text {
  color: var(--primary);
}

.helpingItem--outline:hover .helpingItem__icon {
  background: var(--white);
}

.helpingItem--outline:hover .helpingItem__heading,
.helpingItem--outline:hover .helpingItem__text {
  color: var(--white);
}

.helpingItem--simple {
  cursor: pointer;
  transition: 0.4s ease-in-out;
}

.helpingItem--simple:hover {
  /* margin-top: -10px; */
  opacity: 0.7;
  transition: background 0.3s, border-radius 0.3s, opacity 1s;
}

.helpingItem--simple .helpingItem__icon {
  background: transparent;
  box-shadow: none;
}

.helpingItem--simple .helpingItem__heading,
.helpingItem--simple .helpingItem__text {
  color: var(--white);
  font-weight: 600;
}

.helpingItem--simple .helpingItem__heading::after,
.helpingItem--simple .helpingItem__text::after {
  content: "";
  display: block;
  background: #ffffff;
  width: 20%;
  border-radius: 25px;
  height: 2px;
  margin: 5px auto 0;
}

.helpingItem--simple:hover .helpingItem__heading::after,
.helpingItem--simple:hover .helpingItem__text::after {
  content: "";
  display: block;
  background: white;
  width: 20%;
  border-radius: 25px;
  height: 2px;
  margin: 5px auto 0;
}

.helpingItem--simple:hover .helpingItem__icon {
  background: transparent;
}

.helpingItem--simple:hover .helpingItem__heading,
.helpingItem--simple:hover .helpingItem__text {
  color: white;
}

/*offerSection*/
.offerSection .helpingItem__heading {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}

.offerSection .helpingItem__icon svg {
  width: 75px;
}

/*project section*/

.projectItem {
  position: relative;
  overflow: hidden;
}

.projectItem__media {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  background: #e7e6e4;
  z-index: 1;
}

.mobile {
  transition: 0.75s ease-in-out;
}

.mobile:hover {
  background-color: #c3bfbf52;
  color: #002866;
  border-radius: 8px;
  margin-top: -20px;
}

.hrBuildingBridge {
  background: var(--secondary);
  border-radius: 50px;
  height: 4px;
  width: 25%;
  margin: 0 auto;
}

.backgroundImage__thank {
  background-image: url("../img/thanks.png");
  background-position: center;
  background-repeat: no-repeat;
  padding: 80px 0 0 0;
}

.call__img {
  background-color: var(--secondary);
  padding: 20px;
  border-radius: 50%;
}

.projectItem__media img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-transition: all 1.5s cubic-bezier(0.11, 0.52, 0.53, 0.79) 0s;
  transition: all 1.5s cubic-bezier(0.11, 0.52, 0.53, 0.79) 0s;
  max-width: 100%;
}

.projectItem__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 40, 102, 0.5);
}

.testimonial__background {
  background-image: url("../img/testimonial.jpg");
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 70px 0px 70px 0px;
}

.projectItem__category a {
  position: absolute;
  top: 30px;
  left: 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  color: #002866;
  background: #ffee00;
  padding: 10px 20px;
  z-index: 3;
  text-decoration: none;
}

.projectItem__body {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 40px;
  z-index: 3;
}

.projectItem:hover .projectItem__media img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.projectItem__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  color: var(--white);
  margin-bottom: 0;
}

.raised {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.raised__income,
.raised__goal {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25;
  text-transform: uppercase;
  color: var(--white);
  margin: 24px 0 0;
}

.raised__goal {
  text-align: right;
}

.income {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  color: var(--secondary);
  margin-bottom: 4px;
}

.progress {
  --bs-progress-height: 1.3rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--white);
  --bs-progress-border-radius: 0;
  --bs-progress-box-shadow: none;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: var(--lightYellow);
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

/*involved Section*/

.involvedSection h2,
.involvedSection p {
  color: var(--white);
}

.involvedSection h3 {
  color: var(--secondary);
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.25em;
  margin-bottom: 20px;
}

.involvedSection p {
  font-size: 18px;
  font-weight: 400;
  line-height: 31px;
}

/*events section*/
.eventsSection {
  background: var(--secondary);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 90px 0 240px 0;
}

.event {
  background: var(--white);
  overflow: hidden;
  border: 1px solid #e8e7e7;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.event.has-thumbnail {
  display: flex;
  align-items: center;
}

.event__thumbnail-wrap {
  position: relative;
  width: 150px;
}

.event__thumbnail {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  background: #e7e6e4;
  width: 150px;
}

.event__thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
  -webkit-transition: all 1.5s cubic-bezier(0.11, 0.52, 0.53, 0.79) 0s;
  transition: all 1.5s cubic-bezier(0.11, 0.52, 0.53, 0.79) 0s;
}

.event__read-more {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--primary);
  background: var(--secondary);
  margin-top: 30px;
  padding: 6px 15px;
  opacity: 0;
  border-radius: 2px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  text-decoration: none;
}

.event:hover .event__read-more {
  opacity: 1;
  margin-top: 0;
}

.event:hover .event__thumbnail img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.event__content {
  display: flex;
  align-items: center;
}

.event.has-thumbnail .elementor-event__content {
  width: calc(100% - 150px);
}

.event__content-header {
  width: 60%;
  padding: 20px 40px;
}

.event__title {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 12px;
  color: var(--primary);
}

.event__title a {
  color: inherit;
  text-decoration: none;
}

.event__date {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--dark);
}

.event__address {
  position: relative;
  width: 40%;
  padding: 20px 40px;
}

.event__address:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 1px;
  height: 50px;
  background: var(--dark);
  margin-top: -25px;
}

.event__address-tile {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: var(--primary);
  margin-bottom: 8px;
}

.event .tribe-address {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--dark);
}

.navbar-toggler:focus {
  box-shadow: none;
}

.donateSection {
  margin-top: -165px;
  padding-top: 0;
}

.section--gray {
  background-color: #f3f3f3;
}

.giveTotals {
  display: flex;
  background: var(--white);
  -webkit-box-shadow: 0 4px 50px rgba(0, 0, 0, 0.05);
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.05);
}

.giveTotals__header {
  background: var(--primary);
  padding: 50px 40px 30px 40px;
}

.giveTotals__header__title {
  font-size: 36px;
  line-height: 1.25;
  color: var(--white);
  margin-bottom: 12px;
}

.giveTotals__header__title__desc {
  font-size: 16px;
  line-height: 1.75;
  color: var(--white);
  margin-bottom: 24px;
}

.giveTotals__form {
  text-align: left;
  padding: 60px 50px;
}

.giveTotals__form__sub-title {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 500;
  color: var(--primary);
  margin-bottom: 4px;
}

.giveTotals__form__title {
  font-size: 30px;
  line-height: 1.25;
  color: var(--primary);
  margin-bottom: 8px;
}

.giveTotals__form__desc {
  font-size: 16px;
  line-height: 1.75;
  color: var(--dark);
  margin-bottom: 32px;
}

.giveTotals__input label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.giveTotals__input .input-group-text,
.giveTotals__input .form-control {
  font-size: 24px;
  font-weight: 600;
}

.giveTotals__input .form-control {
  color: var(--primary);
  padding: 0 20px;
}

.giveTotals__checks {
  /* display: grid; */
  gap: 20px;
  /* grid-template-columns: repeat(auto-fit, minmax(80px, 1fr)); */
  margin-bottom: 20px;
}

.customInputFundraise .input-group-text,
.customInputFundraise .form-control {
  border-radius: 0.375rem;
  padding: 12px 16px;
}

.offcanvas form .form-control {
  padding: 12px 20px !important;
}

.offcanvas form .buttonPrimary:hover {
  color: var(--white) !important;
}

.giveTotals__checks .btn {
  font-size: 16px;
  font-weight: 600;
  background-color: var(--primary);
  border: 8px;
  padding: 0 auto;
}
.giveTotals__checks .btn:hover {
  background-color: var(--secondary);
  color: var(--primary);
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: var(--secondary);
  color: var(--primary);
}
.donatenow__modal .modal-dialog {
  max-width: 50%;
  margin-right: auto;
  margin-left: auto;
}
.volunteer-section {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 150px;
}
.donatenow__modal .modal-content {
  padding: 24px;
}

.radio__button .form-check {
  padding: 0 0.5rem;
}

.radio__button .form-label {
  font-size: 16px;
  font-weight: 600;
}

.header__top {
  height: 85px;
}

.header__top.scrolled {
  background: rgb(194, 9, 9);
}

.padding__left-25 {
  padding-left: 25px;
}

.raise__your__hand .helpingItem__icon img {
  width: 50px;
  height: 50px;
}

.what__we_offer .helpingItem__icon img {
  width: 60px;
  height: 60px;
}

.projectSection .container {
  max-width: 75% !important;
  margin: 0 auto;
}

.btn__discover__more .btn-secondary:hover,
.btn__discover__more .btn-secondary:focus,
.btn__discover__more .btn-primary:hover,
.btn__discover__more .btn-primary:focus {
  background-color: var(--white) !important;
  color: var(--primary) !important;
  border-color: var(--white) !important;
}

.custom__amount {
  height: 58px;
  padding: 10px !important;
  line-height: 1;
}

.blog__card .blog__content {
  padding: 25px 35px;
}

.recent_donation {
  overflow: auto !important;
  max-height: 315px;
}

.blog__card {
  background: var(--white);
  border: none;
  background: #ffffff;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 2px;
}

.blog__card .blog__title {
  color: var(--primary) !important;
  line-height: 30px;
  min-height: 60px;
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.section--blogs {
  background-color: rgba(255, 238, 0, 0.15);
  /* background-color: var(--primary); */
}

.sectionHeadingBlog {
  color: var(--white);
}

.blog__card .blog__readMore {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  color: #777;
  border-bottom: 1px solid;
  cursor: pointer;
  margin-bottom: 0;
}

.blog__card .blog__footer {
  background-color: var(--secondary);
  padding: 20px 35px;
  margin: 0;
  display: flex;
  border: none;
  border-radius: 0 0 2px 2px;
}

.blog__card .date {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: uppercase;
  color: #777;
  margin-bottom: 12px;
}

.blog__card .blog__footer div {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: uppercase;
  color: #002866;
}

.blog__card .blog__image {
  object-fit: cover;
  -webkit-transition: all 1.5s cubic-bezier(0.11, 0.52, 0.53, 0.79) 0s;
  transition: all 1.5s cubic-bezier(0.11, 0.52, 0.53, 0.79) 0s;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.blog__card:hover .blog__image {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.blog__card .blog__footer .blog__image {
  height: 16px;
  width: 16px;
  margin-right: 6px;
}

/* footer style */
.section__footer {
  /* background-image: url("../img/bg-footer.jpg"); */
  background-color: var(--primary);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0px 40px 0px;
  position: relative;
}

.overlay {
  background-color: var(--primary);
  opacity: 0.7;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.hero-overlay {
  background: linear-gradient(
    90deg,
    rgb(74 133 230 / 95%) 0%,
    rgb(21 103 228 / 44%) 97%
  );
  opacity: 0.4;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.auth-overlay {
  background-color: var(--primary);
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.footer__input::placeholder {
  color: var(--dark);
}

.footer__input {
  background-color: var(--white);
  border-color: var(--white);
  border-radius: 2px 2px 2px 2px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid var(--dark);
  line-height: 1.5em;
  color: var(--dark);
  min-height: 59px;
  padding: 7px 20px;
  margin-bottom: 20px;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.footer__subscribe__now {
  background-color: var(--secondary);
  color: var(--primary);
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  line-height: 1.5em;
  border-radius: 2px 2px 2px 2px;
  min-height: 59px;
  padding: 7px 20px;
  border: 2px solid var(--secondary);
}

.section__footer .footer__heading {
  color: var(--secondary);
  font-family: var(--font-primary), Sans-serif;
  font-weight: 700;
  line-height: 1.25em;
  margin: 0px 0px 25px 0px;
}

.footer__section1 .footer__heading {
  font-size: 22px;
}

.footer__section2 .footer__heading {
  font-size: 18px;
}

.footer__section3 .footer__heading {
  font-size: 26px;
}

.section__footer .footer__list {
  list-style: none;
  padding-left: 4px;
}

.line3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  color: var(--dark) !important;
}

.section__footer .footer__list li i {
  padding-right: 16px;
  font-size: 18px;
  width: 1.25em;
  font-weight: 900;
}

.section__footer .footer__list li a {
  cursor: pointer;
}

.last-date {
  margin-right: 25px;
}

.section__footer .footer__list li,
.footer__list details .footer__list summary,
.footer__text {
  font-family: var(--font-primary), Sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5em;
  color: var(--white);
  padding-bottom: 10px;
}

.fundRaisingSection .owl-item.active {
  /* min-height: 515px !important;
  height: 515px !important;
  max-height: 515px !important; */
}

.fundRaisingSection .item {
  height: 100% !important;
}

.section__footer .footer__list li:hover a,
.footer__list details:hover summary {
  color: var(--secondary);
}

.section__footer .footer__list li a {
  color: var(--white);
  text-decoration: none;
}

.footer__social__icon {
  display: flex;
  list-style: none;
  padding: 0;
}

.footer__social__icon a {
  color: var(--white);
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__social__icon li {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  margin-right: 5px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 2px;
}

.footer__social__icon li:nth-child(1) {
  background-color: #3b5998;
}

.footer__social__icon li:nth-child(2) {
  background-color: #1da1f2;
}

.footer__social__icon li:nth-child(3) {
  background-color: #25d366;
}

.footer__social__icon li:nth-child(4) {
  background-image: linear-gradient(
    to bottom right,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d
  );
}

.footer__social__icon li:nth-child(5) {
  background-color: #0077b5;
}

.footer__bottom {
  padding: 30px 0;
}

.footer__bottom .section__sub_footer {
  list-style: none;
  display: flex;
}

.footer__bottom .section__sub_footer li {
  margin-right: 30px;
  cursor: pointer;
}

.section__sub_footer li a {
  text-decoration: none;
  color: var(--dark);
}

.navbar__logo {
  width: 160px;
}

.footer__logo {
  width: 180px;
}

.donation--bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 75px 0;
  /* margin-top: 70px; */
  /* height: 50vh; */
}

.donation--bg .donation__badge {
  background-color: var(--secondary);
  color: var(--primary);
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  padding: 14px 20px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.donation__category {
  background: #f8f7f5;
}

.donation__category .blog__content h5 a {
  color: var(--primary);
}

.donation__progress {
  margin-bottom: 24px;
}

.donation__progress .raised {
  margin-bottom: 4px;
}

.donation__progress .raised .income {
  color: var(--primary);
}

.donation__progress .raised .total {
  color: var(--primary);
  font-size: 16px;
}

.donation__progress .progress {
  height: 12px;
  background-color: #eee;
}

.transection__image {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  margin-right: 12px;
}

.recent__transection {
  display: flex;
}

.recent__transection .name {
  font-size: 16px;
  font-weight: 600;
}

.donation__category .paddingLeft__desktop--40 {
  padding-left: 40px;
}

.load__more--transaction,
.load__more--transaction:hover {
  border: none;
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 20px;
  font-weight: 600;
  border-radius: 2px;
  color: #333;
  background-color: #f0f0f0;
  line-height: 20px;
  cursor: pointer;
}

.recent__transection .amount {
  font-size: 14px;
  font-weight: 500;
  color: #0300a8;
}

.recent__transection .date {
  font-size: 14px;
  color: #333;
  margin-bottom: 0;
}

.section--about h2 {
  font-size: 30px;
}

.section--about .button__sponsor button:hover {
  background-color: var(--white);
  /* border: 2px solid var(--white); */
  color: var(--primary);
  border-color: var(--white);
}

.section--charity .counterSection {
  padding-bottom: 155px;
}

.volunteer__section {
  height: 75px;
  width: 75px;
  /* background-color: var(--secondary); */
  border-radius: 50%;
  position: relative;
}

.voluntyeer__description {
  padding-left: 30px;
  flex: 0.85;
}

.volunteer__section img {
  position: absolute;
  top: 50%;
  height: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section__explore {
  cursor: pointer;
}

.section__one {
  padding: 40px 15px 40px 15px;
  border-radius: 8px;
}

.section__one.two {
  background-color: #25a797;
}

.section__one.three {
  background-color: #007fc1;
}

.section__one.one {
  background-color: #79b04a;
}

.volunteer__page {
  z-index: 99;
}

.section__one img {
  height: 80px;
  width: 80px;
}

.section__one h4 {
  color: var(--white);
  margin-bottom: 40px;
  text-align: center;
}

.navbar {
  z-index: 1001;
}

.volunteer__form {
  margin-top: -171px;
  padding-top: 250px;
}

.bocomeVolunteer {
  display: inline-block;
  padding: 15px 0px;
  /* border: 1px solid #ffffff;
  border-radius: 30px; */
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--white);
  border: 0;
  background-color: transparent;
}

.form__label--volunteer {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5em;
  color: var(--primary);
}

.header__menu {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
}

.header__menu li {
  margin: 0 20px;
}

.header__menu li:last-child {
  margin-right: 0;
}

.header__menu .menus {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

.header__menu .menus:hover {
  color: var(--secondary) !important;
}

.menus:hover {
  color: var(--secondary) !important;
}

.section__contact {
  background-color: var(--primary);
  padding-bottom: 25%;
  color: var(--white);
}

.section__contact h3,
.section__contact h5 {
  color: var(--white);
  margin-bottom: 16px;
}

.section__form--contact .section--gray {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper .section__form--contact + .section__footer {
  margin-top: 30%;
}

/* responsiveness */

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #383636;
  /* Blue */

  .donateSection {
    margin-top: -50%;
  }

  .mobile-view {
    margin-top: 1rem;
  }

  .giveTotals__header__title__desc {
    margin-bottom: 8px;
  }

  .hero-section {
    margin: 0;
  }

  .raised__income,
  .raised__goal {
    margin: 0;
  }

  .giveTotals__input .form-control::placeholder {
    color: var(--primary) !important;
  }

  .income {
    font-size: 20px;
  }

  .giveTotals__header__title {
    font-size: 28px;
  }

  .giveTotals {
    background-color: transparent;
  }

  .event__thumbnail {
    padding-bottom: 66%;
  }

  .event.has-thumbnail,
  .event__content {
    display: block;
  }

  .giveTotals__form {
    padding: 30px 16px;
  }

  .mobile__block {
    display: block;
  }

  .giveTotals__form__title {
    font-size: 24px;
  }

  .giveTotals__form__desc {
    margin-bottom: 20px;
  }

  .giveTotals__input .input-group-text,
  .giveTotals__input .form-control {
    padding: 6px 20px;
  }

  .giveTotals__checks .btn {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 12px;
  }

  .custom__amount {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .backgroundImage__thank {
    padding: 15px 0;
  }

  .call__img {
    margin-bottom: 30px;
  }

  .call__img svg {
    width: 32px;
    height: 32px;
  }

  .blog__card .blog__content {
    padding: 15px 20px;
  }

  .mobile__donate {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 99;
  }

  .footer__bottom {
    margin-bottom: 60px;
  }

  .mobile__donate button {
    border-radius: 0;
  }

  .donation--bg .donation__badge {
    font-size: 18px;
    white-space: nowrap;
  }

  .fundraiser--bg .content div {
    width: 100% !important;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #383636;
  /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}

.detail__page .projectItem__title {
  color: var(--primary);
}

.detail__page .raised__income,
.detail__page .raised__goal {
  margin-top: 14px;
  color: var(--primary);
}

.detail__page .income {
  font-size: 28px;
  color: #0300a8;
  margin-right: 4px;
  margin-bottom: 0;
}

.detail__page .raisedS {
  font-weight: 600;
  font-size: 16px;
  color: #0300a8;
  margin: 0 4px;
}

.detail__page .progress {
  background-color: #ccd1d55e;
}

.donation__category .tags1 span {
  background-color: var(--primary);
  color: white;
  margin: 0 2px;
  font-size: 12px;
  padding: 5px 8px;
  border-radius: 2px;
}

.donation__category .share {
  display: flex;
}

.donation__category .share span {
  background-color: var(--primary);
  color: white;
  margin: 0 2px;
  font-size: 12px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  border-radius: 2px;
}

.donation__category .taggg {
  color: #0300a8;
}

.donation__category .description__text {
  font-size: 36px;
  margin-bottom: 20px;
}

.flex1 {
  flex: 1;
}

.owl-dots {
  display: none;
}

.fundRaisingSection.mobile .owl-nav {
  display: none !important;
}

.fundRaisingSection .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  display: flex !important;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.fundRaisingSection .owl-nav button.owl-prev,
.fundRaisingSection .owl-nav button.owl-next,
.fundRaisingSection button.owl-dot {
  border: none;
  padding: 0 !important;
  font: inherit;
  height: 40px;
  width: 40px;
  background: var(--white) !important;
  border-radius: 50%;
  font-size: 34px !important;
  color: var(--primary) !important;
  line-height: 20px !important;
}

.owl-nav button.owl-next:hover,
.owl-nav button.owl-prev:hover {
  background: var(--secondary) !important;
}

.testimonialSection img {
  height: 100%;
}

.fundRaisingSection .owl-prev {
  margin-left: -80px !important;
  position: relative;
}

.fundRaisingSection .owl-next {
  margin-right: -80px !important;
  position: relative;
}

.fundRaisingSection .owl-prev span,
.fundRaisingSection .owl-next span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -66%);
}

.fundRaisingSection .card-title {
  color: var(--primary);
  line-height: 30px;
  /* min-height: 60px; */
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.fundrasiseProfileImg {
  height: 35px;
  width: 35px;
}

.fundrasiseProfileImg img {
  border-radius: 50%;
}

.fundRaisingProgress .progress {
  height: 10px;
  border-radius: 4px;
  background-color: #cfcfcf4d;
  margin-bottom: 12px;
}

.fundRaisingSection .owl-stage-outer {
  z-index: 999;
}

.fundRaisingImgBackground1 {
  max-height: 300px;
  min-height: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: calc(0.375rem - 1px);
}

.fundRaisingImgBackground2:hover {
  transform: scale(1.07);
  opacity: 0.7;
  transition: opacity 1s;
}

.fundRaisingImgBackground2 {
  max-height: 300px;
  min-height: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: calc(0.375rem - 1px);
  transition: all 1.5s cubic-bezier(0.11, 0.52, 0.53, 0.79);
}

.fundRaisingImgBackground3 {
  max-height: 250px;
  min-height: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: calc(0.375rem - 1px);
}

.cardSlider .carouse-linner1 .carousel-inner {
  max-height: 250px;
  min-height: 250px;
  height: 250px;
}

.fundrasiseUrgentCase .fundRaisingImgBackground {
  max-height: 230px;
  min-height: 230px;
  height: 230px;
  object-fit: cover;
}

.carousel-indicators [data-bs-target] {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.fundRaisingImgBackground {
  display: block;
  width: 100%;
  max-height: 200px;
  height: 200px;
  transition: all 0.6s;
  overflow: hidden;
  object-fit: cover;
}

.fa-angle-left,
.fa-angle-right {
  height: 20px;
  width: 20px;
}

.fa-paperclip {
  position: absolute;
  height: 40px;
  width: 40px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  border-radius: 50%;
  right: 50px;
  color: var(--white);
  top: 0;
  cursor: pointer;
}

.uploadFileComment {
  opacity: 0;
  position: absolute;
  height: 40px;
  width: 40px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  border-radius: 50%;
  right: 50px;
  color: var(--white);
  top: 0;
  cursor: pointer;
}

.recent__transection .fa-angle-left:hover,
.recent__transection .fa-angle-right:hover {
  background: #ccc;
  color: var(--white);
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.fundRaisingImgBackgroundDetails {
  max-height: 469px;
  height: 469px;
  display: block;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  transition: all 0.6s;
  overflow: hidden;
}

.fundRaisingSection .item .card {
  overflow: hidden;
}

.fundRaisingSection .card-body {
  text-decoration: none;
}

.fundRaisingSection a {
  text-decoration: none;
}

.fundRaisingSection .card-text span,
.fundSection {
  color: var(--dark);
}

.colorSuccess {
  color: var(--dark);
}

.read-more {
  color: #777;
  display: inline-block;
  margin-bottom: 8.5px;
  font-size: 11px;
  text-transform: uppercase;
  border-bottom: 1px solid #777;
}

/* button style */
.buttonSecondry {
  transition: all 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--primary);
  border: 2px solid var(--primary) !important;
  border-radius: 2px;
  padding: 12px 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.additional-style {
  border: 2px solid var(--white);
  background: var(--white) !important;
}

.buttonSecondry:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* border-radius: 2px; */
  z-index: -2;
}

.buttonSecondry:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background-color: var(--primary);
  transition: all 0.5s;
  /* border-radius: 2px; */
  z-index: -1;
}

.buttonSecondry:hover {
  color: #fff;
}

.buttonSecondry:hover:before {
  width: 100%;
}

.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 38px;
  width: 55px;
  background-color: var(--primary);
  border-radius: 12px;
}

.urgentFundraiseShare {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 111;
  background-color: white;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 4px 8px;
  color: var(--primary);
  font-size: 12px;
  font-weight: 600;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.urgentFundraise {
  position: absolute;
  top: 4px;
  left: 4px;
  border-top-left-radius: 16px;
  border-bottom-right-radius: 16px;
  z-index: 999;
  background-color: white;
  /* border-radius: 4px; */
  text-transform: uppercase;
  padding: 4px 8px;
  color: var(--primary);
  font-size: 20px;
  font-weight: 600;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.stpe-badge {
  position: absolute;
  z-index: 999;
  background-color: var(--primary);
  border-top-left-radius: 16px;
  border-bottom-right-radius: 16px;
  text-transform: uppercase;
  padding: 4px 8px;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.fund-tag {
  position: absolute;
  right: 4px;
  bottom: 4px;
  z-index: 999;
  background-color: var(--white);
  border-radius: 4px;
  text-transform: uppercase;
  padding: 4px 8px;
  color: var(--primary);
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.cardSlider .carousel-indicators button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.playIcon .fa {
  font-size: 20px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
}

.buttonPrimary {
  transition: all 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight: 600;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--primary);
  border-radius: 8px;
  padding: 14px 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 0;
}

.buttonPrimary:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  z-index: -2;
}

.buttonPrimary:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: var(--secondary);
  border-radius: 2px;
  z-index: -1;
}

.buttonPrimary:hover {
  color: var(--primary) !important;
  background: transparent;
}

.buttonPrimary:hover:before {
  width: 100%;
}

.donation-heading:hover {
  color: var(--secondary) !important;
  background: transparent;
}

.btn__secondary {
  background: transparent;
  box-shadow: none !important;
}

.btn__primary {
  background: var(--primary);
  color: var(--white);
  border-radius: 8px;
}

a {
  text-decoration: none;
}

.text-right {
  text-align: right;
}

.testimonialSection .card {
  border: 0;
}

/* fundRaiser Style */
.days-left {
  transition: all 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight: 600;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--primary);
  border-radius: 8px;
  padding: 14px 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 0;
  min-width: 150px;
}

.days-left:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  z-index: -2;
}

.days-left:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: var(--secondary);
  border-radius: 2px;
  z-index: -1;
}

.fundraiser--bg {
  min-height: 380px;
  background-size: cover;
  margin-top: 70px;
}

.fundraiser--bg .content {
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 380px;
  align-items: start;
}

.fundraiser--bg .content h1 {
  color: var(--white);
}

.fundraiser--bg .content h5 {
  color: var(--white);
  line-height: 32px;
  font-size: 26px;
  font-weight: 500;
}

.fundraiser--bg .content div {
  width: 45%;
}

.search__modal .modal-dialog {
  max-width: 100%;
  margin: 0;
}

.search__modal .modal-dialog .modal-content {
  height: 25%;
  border-radius: 0;
}

/* Fundraiser Page style*/
.filterSideBar {
  background-color: var(--primary);
  color: var(--white);
  padding: 24px;
  border-radius: 4px;
}

.filterSideBar h5 {
  color: var(--white);
  display: inline-block;
  line-height: 45px;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--secondary);
}

.filterSideBar ul {
  list-style: none;
  padding: 0;
}

.filterSideBar ul li {
  padding: 7px 0;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}

.filterSideBar ul li:hover {
  color: var(--secondary);
}

.filterSideBar button {
  margin-top: 30px;
}
.fundraiser-flx {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}
.li-fundraiser-card {
  min-width: 350px;
}
.filterMainContent .searchIcon {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.filterMainContent .cardTitle {
  color: var(--primary);
  line-height: 30px;
  /* min-height: 60px; */
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.filterMainContent .customSelect {
  border: 1px solid var(--primary);
  padding: 6px 14px;
  border-radius: 4px;
}

/* Fundraiser Details */
.fundraiserDetail {
  margin-top: 70px;
}

.fundraiserDetail .nav-tabs {
  border-bottom: 1px solid var(--primary);
}

.fundraiserDetail .nav-tabs .nav-link {
  padding: 8px 26px;
  border: 0;
}

.fundraiserDetail h3 {
  font-size: 34px;
  font-weight: 600;
}

.fundraiserDetail .nav-tabs .nav-link:hover {
  color: var(--primary);
  border: 0 !important;
  border-bottom: 3px solid var(--primary) !important;
}

.fundraiserDetail .nav-tabs .nav-link.active {
  border: 0;
  border-bottom: 3px solid var(--primary);
  font-weight: 700;
}

.aboutSectionFundraise h4 {
  text-align: center;
  font-weight: 600;
}

.commentsSectionFundraise textarea {
  resize: none;
  height: 150px;
  padding-right: 100px;
}

.line2 {
  line-height: 30px;
  min-height: 60px;
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.donationModalFundraise div {
  flex: 1;
}

.urgentFundrasise .owl-nav {
  display: none;
}

.urgentFundrasise .owl-dots {
  display: block;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translate(-50%);
}

.activeFundraiseMenu {
  color: var(--primary);
  font-weight: 600 !important;
}

.urgentFundrasise .card-text {
  line-height: 24px;
  min-height: 96px;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.urgentFundrasise img {
  object-fit: cover;
  border-radius: 0;
  overflow: hidden;
}

.donationsCard {
  margin-bottom: 180px;
}

.donationsCard .cardRow {
  position: absolute;
  top: -70px;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 150px;
}

.urgentFundrasise .urgentFundraise {
  background-color: var(--primary);
  color: var(--secondary);
  padding: 1px 6px;
  font-size: 11px;
  text-transform: uppercase;
}

.clearfilter {
  cursor: pointer;
  color: var(--danger);
  font-weight: 600;
}

.searchValue {
  list-style: none;
  padding: 0;
}

.searchValue li {
  color: var(--primary);
  font-weight: 600;
  padding: 4px;
  cursor: pointer;
}

.searchValue li .urgentvalue {
  background-color: var(--primary);
  color: var(--secondary);
  padding: 6px 12px;
  font-size: 12px;
  border-radius: 4px;
  margin-left: 24px;
  text-transform: uppercase;
}

/* authentication style */
.sideSectionAuthentication {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 730px;
  background-color: var(--primary);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.forgotImageUrl {
  background-image: url("../img/forgot-password.png");
}

.singinImageUrl {
  background-image: url("../img/Signin.png");
}

.singupImageUrl {
  background-image: url("../img/signup.png");
}

.cardShadow {
  border-radius: 4px;
  overflow: hidden;
}

.signUpSection {
  margin-top: 70px;
  padding: 50px 0;
}

.signUpSection h2 {
  font-weight: 700;
}

.signUpSection .welcomeTxt {
  color: var(--white);
}

.signUpSection .para {
  font-size: 22px;
  line-height: 1.54;
  color: var(--white);
  opacity: 0.8;
}

.signUpSection .cardShadow {
  min-height: 730px;
}

.formAuthentication {
  min-height: 730px;
  display: flex;
  align-items: center;
}

.forgotPasswordAuth {
  cursor: pointer;
  text-decoration: underline;
  margin: 16px 0;
}

.forgotPasswordAuth:hover,
.forgotPasswordAuth a:hover {
  color: var(--primary);
  font-weight: 500;
}

.forgotPasswordAuth a {
  color: var(--dark);
}

.answerQuestion .accordion-item {
  color: var(--dark);
}

.answerQuestion .accordion-button:focus {
  box-shadow: none;
}

.answerQuestion .accordion-button:not(.collapsed) {
  color: var(--primary);
  background-color: none;
}

.urgentTxt {
  margin-left: 20px;
  background-color: rgb(237, 114, 114);
  padding: 4px 12px;
  color: var(--white);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  border-radius: 25px;
}

.accordion-header button {
  background-color: var(--bs-accordion-active-bg);
  font-weight: 600;
  color: var(--primary);
}

.stillNeedHelp {
  border-radius: 4px;
}

.offcanvas.offcanvas-start {
  width: 450px;
}

.inputAmmount,
.inputAmmount::placeholder,
.inputAmmount:focus {
  font-size: 24px;
  text-align: end;
  font-weight: 500;
  color: var(--primary);
  padding-right: 10px;
  padding-left: 0;
  background-color: transparent;
  border: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.customFieldPrice {
  border-radius: 8px;
  overflow: hidden;
  /* border: 2px solid var(--primary); */
}

.ant-slider-track {
  position: absolute;
  background-color: var(--secondary) !important;
  border-radius: 2px;
  transition: background-color 0.2s;
}

.ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px var(--primary);
}

.ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 2px var(--primary);
}

.customTipInput input {
  border-radius: 8px;
}

.customTipInput #basic-addon1 {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.termConditionTxt {
  color: var(--secondary);
  font-weight: 500;
  text-decoration: underline;
}

.termConditionTxt:hover {
  color: var(--primary);
}

fieldset .form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.borderDanger {
  border: 2px solid var(--danger) !important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: 0 !important;
}

.borderPrimary {
  border: 2px solid var(--primary) !important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: 0 !important;
}

.btnTransparent,
.btnTransparent:hover {
  background-color: transparent !important;
  font-size: 16px;
  font-weight: 600;
  /* color: var(--primary); */
  text-decoration: none;
  cursor: pointer;
  border: 0;
  width: auto;
  padding: 10px 0;
}

.descriptionFund {
  color: var(--primary);
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.dropdownMenuProfile a,
.dropdown-item:focus,
.dropdown-item:hover,
.dropdownMenuProfile a:focus,
.dropdownMenuProfile a:active {
  color: var(--dark);
  background-color: var(--white);
}

.uploadImagesprofile {
  height: 180px;
  width: 180px;
  background-color: #e7e7e7;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
}

.uploadImagesprofile .fa-camera {
  position: absolute;
  bottom: 15px;
  right: 10px;
  color: var(--secondary);
  background: var(--primary);
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.uploadFile {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 99;
  cursor: pointer;
}

.ant-table-cell:nth-child(3) {
  width: 180px;
}

.ant-table-row .ant-table-cell:nth-child(3) {
  width: 180px;
  /* color: var(--primary); */
  line-height: 20px;
  height: 80px;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: -webkit-box; */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.social__section2 {
  position: sticky;
  top: 30%;
  left: 5px;
  transform: translate(0, -50%);
  display: inline-flex;
  z-index: 998;
}

.social__section2 .footer__social__icon {
  list-style: none;
  padding: 0;
  display: block;
}

.social__section2 .footer__social__icon a {
  color: var(--white);
}

.social__section2 .footer__social__icon li {
  height: 48px;
  width: 48px;
  color: var(--white);
  margin-bottom: 5px;
  margin-left: 5px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 2px;
}

.primaryAlert {
  background-color: var(--primary);
  color: var(--white);
  font-weight: 500;
}

.backArrow {
  color: var(--primary);
}

.thanksYouPage {
  background-image: url("../img/thanks.png");
  background-position: center;
  /* background-repeat: no-repeat; */
}

.commentPreviewImage {
  object-fit: cover;
  margin-right: "10px";
  height: 50px;
  width: 50px;
  margin-right: 8px;
  border-radius: 8px;
}

.fasend {
  position: absolute;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  border-radius: 50%;
  right: 15px;
  color: var(--white);
  bottom: 15px;
  cursor: pointer;
}

.singleComment {
  background: #f4f4f7;
  padding: 15px;
  border-radius: 8px;
}

.filterMainContent .card-title {
  color: var(--primary);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* .angleDownArrow{
  position: absolute;
    bottom: -20px;
    background-color: var(--primary);
    width: 80px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    left: 50%;
    transform: translateX(-50%);
    font-size: 22px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
} */
.setpOne {
  font-weight: 600;
  height: 2.5rem;
  background: #002866;
  display: flex;
  width: 2.5rem;
  font-size: 1.2rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: 25px;
  color: #fff;
}

.image-section-style {
  background-position: 198px -13px;
  height: 80px;
  width: 80px;
  text-align: center;
  margin: 0 auto 15%;
  /* border: 1px solid #eeeeee; */
  box-sizing: border-box;
  /* background-color: #fff; */
  border-radius: 20px;
  background-repeat: repeat;
}

.image-style {
  background-position: 198px -13px;
  height: 70px;
  width: 70px;
  text-align: center;
  margin: 0 auto 15%;
  /* border: 1px solid #eeeeee; */
  box-sizing: border-box;
  /* background-color: #fff; */
  border-radius: 20px;
  background-repeat: repeat;
}

.start-fundraise-btn {
  background: var(--white) !important;
  border: none !important;
}
.google-divider {
  width: 2px;
  height: 70px; /* Adjust height as needed */
  background-color: #ccc;
  margin-right: 10px; /* Adjust margin as needed */
  margin-left: 10px; /* Adjust margin as needed */
}
.google-icon {
  width: 40px;
  height: 40px;
}
.google-container {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
}
/* User Dashboard */
.user-dashboard {
  margin-top: 70px;
}

/* .gallery .owl-item.active{
  height: 200px !important;
  max-height: 200px !important;
} */

.heading-color .heading-p .heading {
  color: var(--white) !important;
}

.footer-copyright-color {
  color: var(--white);
}

.footer-copyright-color :hover a {
  color: var(--secondary);
}

.logo-style {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.footer-input::placeholder {
  color: var(--white);
  font-size: 16px;
}

.footer-input,
.footer-input:focus {
  background: transparent;
  font-size: 16px;
  border: 2px solid var(--white);
  color: var(--white);
  border-radius: 0;
}

.gallery .item {
  height: 100% !important;
}

.gallery .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  display: flex !important;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.gallery .owl-nav button.owl-prev,
.gallery .owl-nav button.owl-next,
.gallery button.owl-dot {
  border: none;
  padding: 0 !important;
  font: inherit;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  font-size: 34px !important;
  color: var(--primary) !important;
  line-height: 20px !important;
}

.gallery .owl-nav button.owl-prev,
.gallery .owl-nav button.owl-next:hover {
  background: var(--secondary);
}

.testimonialSection img {
  height: 100%;
}

.gallery .owl-prev {
  margin-left: -80px !important;
  position: relative;
}

.gallery .owl-next {
  margin-right: -80px !important;
  position: relative;
}

.gallery .owl-prev span,
.gallery .owl-next span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -66%);
}

.gallery .card-title {
  color: var(--primary);
  line-height: 30px;
  /* min-height: 60px; */
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.gallery .owl-stage-outer {
  z-index: 999;
}

.gallery .item .card {
  overflow: hidden;
}

.gallery .card-body {
  text-decoration: none;
}

.gallery a {
  text-decoration: none;
}

.gallery .card-text span,
.fundSection {
  /* color: rgb(255, 255, 255); */
  color: var(--dark);
}

.fundSection {
  width: 100%;
  position: relative;
  bottom: 0;
  padding: 0px 12px;
  right: 0;
}
.step-card-body {
  width: 100%;
  position: relative;
  bottom: 0;
  padding: 0px 12px;
  right: 0;
  height: 480px;
}
.verification-step-content {
  margin: 60px auto;
}
.verification-step-content h5 {
  margin-bottom: 20px;
}
.verification-step-main {
  display: flex;
  gap: 32px;
  justify-content: center;
  align-items: center;
}
.step-card-design {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border-radius: 16px !important;
  width: 30%;
  background-color: #ffffff;
  margin-bottom: 30px;
  box-shadow: 2px 6px 15px 0px rgba(69, 65, 78, 0.1);
  border: 0px;
}

.fundSectionTrending {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0px 12px;
  color: var(--white) !important;
  right: 0;
}

.owl-item:hover {
  /* margin-top: -10px; */
  /* opacity: 0.7;
  transition: background 0.3s, border-radius 0.3s, opacity 1s; */
}

.fun-donate-btn {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 8px;
  font-weight: 600;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  padding: 14px 30px;
  cursor: pointer;
}

.fun-donate-btn:hover {
  background-color: var(--secondary) !important;
}

.gallery .owl-nav {
  position: absolute;
  top: 50%;
  display: flex !important;
}

.btn-border-primary {
  border: 2px solid var(--primary) !important;
}

.btn-border-secondry {
  border: 2px solid var(--secondary) !important;
}

.social-icon {
  width: 36px;
}

.social-icons {
  margin-bottom: 15px;
}
.social-icons:hover {
  margin-top: -10px;
}

.banner-heading {
  font-size: 48px;
  color: #fff;
  opacity: 0.9;
}

.banner-desc {
  font-size: 24px;
  color: #fff;
  opacity: 0.9;
  font-weight: 500;
}

.fund-overlay {
  background-color: rgba(0, 0, 0, 0.473);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  font-size: 30px !important;
  transition: all 1s;
}

.fund-overlay:hover {
  background-color: rgba(0, 0, 0, 0.24);
  color: black !important;
}

.text-size-para {
  font-size: 18px;
  text-align: justify;
}

.grid-container {
  display: grid;

  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* grid-auto-columns: minmax(0, 1fr); */
  /* grid-auto-flow: column; */
  padding: 10px;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  /* padding: 20px; */
  padding: 2px;
  font-size: 30px;
  text-align: center;
}
.form-check .form-check-input {
  float: left;
  margin-left: 0em;
}
.payment-check {
  border: 2px solid;
  border-radius: 8px;
  margin-bottom: 8px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item-error,
:where(.css-dev-only-do-not-override-14wwjjs).ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-error,
:where(.css-dev-only-do-not-override-14wwjjs).ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-error {
  border-color: #d9d9d9 !important;
}
/* 
.ant-tooltip,
.anticon-eye,
.ant-upload-list-item-actions button {
  display: block !important;
} */

.ant-upload-list-item-container,
.ant-upload-list-item.ant-upload-list-item-error,
.ant-upload-list-item-thumbnail,
.ant-upload.ant-upload-select,
.ant-upload-list.ant-upload-list-picture-card {
  height: 180px !important;
  width: 180px !important;
  background-color: #e7e7e7 !important;
  border-radius: 50% !important;
  position: relative;
  margin: 0 auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
}

/* .cnic-img .ant-upload-list-item-container, */
.cnic-img .ant-upload-list-item.ant-upload-list-item-error,
.cnic-img .ant-upload-list-item-thumbnail,
.cnic-img .ant-upload.ant-upload-select
/* .cnic-img .ant-upload-list.ant-upload-list-picture-card  */ {
  height: 165px !important;
  width: 270px !important;
  background-color: #e7e7e7 !important;
  border-radius: 50% !important;
  position: relative;
  margin: 0 auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
}
/* .fund-img .ant-upload-list-item-container,
.fund-img .ant-upload-list-item.ant-upload-list-item-error,
.fund-img .ant-upload-list-item-thumbnail,
.fund-img .ant-upload.ant-upload-select,
.fund-img .ant-upload-list.ant-upload-list-picture-card {
  height: 180px !important;
  width: 180px !important;
  background-color: #e7e7e7 !important;
  border-radius: 50% !important;
  position: relative;
  margin: 0 auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
} */
:where(
    .css-dev-only-do-not-override-14wwjjs
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item::before,
:where(
    .css-dev-only-do-not-override-14wwjjs
  ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item::before,
:where(
    .css-dev-only-do-not-override-14wwjjs
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item::before,
:where(
    .css-dev-only-do-not-override-14wwjjs
  ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item::before {
  position: absolute;
  z-index: 1;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  background-color: transparent;
  opacity: 0;
  transition: all 0.3s;
  content: " ";
}

.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item {
  border: 0px !important;
}

.cnic-img .ant-upload.ant-upload-select {
  border-radius: 8px !important;
}

.cnic-img .ant-upload-list-picture-card,
.cnic-img .ant-upload-list-item-container {
  border-radius: 12px !important;
  position: relative;
  margin: 0 auto !important;
  align-items: center;
  justify-content: center;
  height: auto !important;
  width: auto !important;
  display: inline-flex !important;
  background-color: transparent !important;
  color: var(--primary);
  margin-right: 20px !important;
}

:where(.css-dev-only-do-not-override-14wwjjs).cnic-img
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .cnic-img
  .ant-upload-list.ant-upload-list-picture-card
  .cnic-img
  .ant-upload-list-item::before,
:where(
    .css-dev-only-do-not-override-14wwjjs
  ).cnic-img.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .cnic-img.ant-upload-list.ant-upload-list-picture-card
  .cnic-img.ant-upload-list-i.tem::before,
:where(
    .css-dev-only-do-not-override-14wwjjs
  ).cnic-img.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .cnic-img.ant-upload-list.ant-upload-list-picture-circle
  .cnic-img.ant-upload-list-item::before,
:where(
    .css-dev-only-do-not-override-14wwjjs
  ).cnic-img.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .cnic-img.ant-upload-list.ant-upload-list-picture-circle
  .cnic-img.ant-upload-list-item::before {
  position: absolute;
  z-index: 1;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  background-color: transparent;
  opacity: 0;
  transition: all 0.3s;
  content: " ";
}

.cnic-img.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item,
.cnic-img.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item,
.cnic-img.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .cnic-img.ant-upload-list-item {
  border: 0px !important;
}

.fund-card:hover:not(:has(*:hover)) {
  opacity: 0.7;
  transition: background 0.3s, border-radius 0.3s, opacity 1s;
}

.blog-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ant-table-wrapper {
  clear: both;
  max-width: 100%;
  width: 100% !important;
  overflow-x: auto;
}

.feed-container {
  height: 400px;
  overflow-y: auto;
}

.cnic-img .ant-upload-list-item-thumbnail {
  border-radius: 8px !important;
  overflow-x: auto;
  position: relative;
}

.fund-img .ant-upload-list-item-thumbnail {
  border-radius: 8px !important;
  overflow-x: auto;
  position: relative;
}

.fund-img {
  position: relative;
  width: 190px;
}

.cnic-img {
  position: relative;
  width: 194px;
}

#imageDeleteIcon2 {
  color: #fff;
  position: absolute;
  right: 5% !important;
  top: 6%;
  background: #000;
  border-radius: 50%;
  height: 35px;
  font-size: 16px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.profile-img {
  position: relative;
  width: 200px;
  margin: 0 auto;
}

.fund-image-delete {
  z-index: 999;
  right: -10px !important;
}

.cnic-image-delete {
  z-index: 999;
  right: -35px !important;
}

/* .fund-img .ant-upload-list-item-thumbnail {
  border-radius: 10px !important;
} */
.fund-img .ant-upload {
  border-radius: 10px !important;
  /* width: 130px !important;
  height: 150px !important; */
}

.fund-img .ant-upload-list.ant-upload-list-picture-card {
  width: 150px !important;
  height: 120px !important;
  overflow: hidden;
  border-radius: 10px !important;
}

.cnic-img .ant-upload-list.ant-upload-list-picture-card {
  /* width: 375px !important; */
  /* height: 230px !important; */
}

.fund-img
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-container,
.profile-img
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-container {
  display: flex;
  width: 102px;
  height: 102px;
  margin-block: 0 8px;
  margin-inline: 0 8px;
  vertical-align: top;
}

.cnic-img
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-container {
  display: inline-flex;
  width: 102px;
  height: 102px;
  margin-block: 0 8px;
  margin-inline: 0 8px;
  vertical-align: top;
}

.cnic-img
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-thumbnail
  img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.fund-img
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-thumbnail
  img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

/* .fund-img .ant-upload-list-picture-card{
width: 130px !important;
height: 150px !important;

}

.fund-img .ant-upload-list-item{
  height: 130px !important;
  border-radius: 10px !important;
}
.ant-upload-list-item-container{
  border-radius: 10px !important;
  height: 130px !important;
}
.fund-img img{
  width: 130px !important;
  border-radius: 10px !important;
}
.ant-upload-list-item-thumbnail{
  border-radius: 10px !important;
  width:  130px !important;
  height: 135px !important;
}

.fund-img .ant-upload-list-picture-card {
  width: 130px !important;
  height: 133px !important;
  border-radius: 10px !important;
}
.fund-img .ant-upload-list-item {
  width: 130px !important;
} */

.modal-backdrop {
  z-index: 0 !important;
}

#imageDeleteIcon6 {
  color: #fff;
  position: absolute;
  top: -8px;
  right: 10px !important;
  background: #000;
  border-radius: 50%;
  height: 24px;
  font-size: 12px;
  width: 24px;
  display: flex;
  align-items: center;
  z-index: 999;
  justify-content: center;
}

#imageDeleteIconDocument {
  color: #fff;
  position: absolute;
  top: -8px;
  right: -5px !important;
  background: #000;
  border-radius: 50%;
  height: 24px;
  font-size: 12px;
  width: 24px;
  display: flex;
  align-items: center;
  z-index: 999;
  justify-content: center;
}
#videoDeleteIcon {
  color: #fff;
  position: absolute;
  top: -8px;
  right: -5px !important;
  background: #000;
  border-radius: 50%;
  height: 24px;
  font-size: 12px;
  width: 24px;
  display: flex;
  align-items: center;
  z-index: 999;
  justify-content: center;
}
#whatsapp-chat-close {
  position: fixed;
  bottom: 150px;
  right: 13px;
  overflow: hidden;
  background: white;
  border-radius: 50%;
  height: 24px;
  font-size: 16px;
  width: 24px;
  display: flex;
  align-items: center;
  z-index: 999;
  justify-content: center;
}
.testimonial-background {
  background-color: var(--white) !important;
  border-radius: 20px !important;
}

.about-card-sec {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.about-card {
  width: 400px;
}

.cause-container {
  height: 450px;
  overflow-y: auto;
}

/* Fundraiser steps */
.step-flex {
  display: flex;
  justify-content: space-between;
}

.step-container {
  margin: auto;
  max-width: 1250px;
  padding: 0px 20px;
}

.step-card-first {
  width: 29%;
}

.step-card {
  width: 29%;
}

.step-border {
  margin: 0px 0px;
  border: 3px solid;
  border-top-color: #00296600;
  border-right-color: #002866;
  border-bottom-color: #002866;
  border-left-color: #002866;
  border-radius: 10px;
}

.step-border-top-left,
.step-border-top-right {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border: 3px solid;
  border-top-color: #002866;
  border-right-color: #00296600;
  border-bottom-color: #00296600;
  border-left-color: #00296600;
  width: 15px;
}

.step-border-top-right {
  right: 0 !important;
  left: auto;
}

.step-title {
  margin-top: 10px;
}

.step-arrow,
.step-arrow-second {
  width: 240px;
  position: absolute;
  top: 70px;
  left: 270px;
}

.step-arrow-second {
  left: auto;
  right: 272px;
}

.step-img {
  width: 170px;
}

.don-table .ant-table-thead .ant-table-cell {
  background-color: rgba(176, 179, 178, 0.012) !important;
  color: #000 !important;
}

.sec-uplift {
  max-width: 1230px;
  margin: auto;
  padding: 3.125rem 2.1875rem 6.25rem;
  border-radius: 10px;
  margin-top: -130px;
  margin-bottom: 50px;
  z-index: 1000;
  background-color: rgb(243, 240, 240);
}

.feed-divider {
  border-bottom: 1px solid rgb(0, 0, 0, 0.1);
  width: 100%;
  margin: 10px 10px;
}

.testi-flex {
  display: flex;
  align-items: center;
}
.how-work-title {
  color: #0077b5;
  font-size: 18px;
}

.news-letter {
  margin-top: 100px;
}

.news-letter-banner {
  width: 100%;
  margin-top: 20px;
}

/* success story */

.divider-gray {
  width: 50%;
  margin: 20px 0px;
  background: #b3b3b3;
  height: 2px;
}

.social-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.scs-scl-btn {
  display: flex;
  align-items: center;
  gap: 3px;
  background-color: #3b5998;
  color: #ffffff;
  padding: 8px 20px;
  border-radius: 10px;
  text-decoration: none;
  /* no underline */
}

.scs-scl-btn:hover {
  color: #ffffff;
}

.isnta-bg {
  background: linear-gradient(
    98.88deg,
    #5757cb -2.3%,
    #995399 22.72%,
    #cf5071 42.88%,
    #f44e55 71.39%,
    #ffd153 94.75%
  );
}

.linkedin-bg {
  background: #0077b5;
}

.youtube-bg {
  background: #ff0000;
}

.bg-x {
  background-color: #000000;
}

.success-banner {
  width: 100%;
  border-radius: 8px;
}

.scs-fund-flex {
  display: flex;
  gap: 10px;
  color: #777;
  border-bottom: 1px solid rgb(230, 229, 229);
}

.scs-fund-flex:hover {
  color: #777;
}

.scs-fund-img {
  object-fit: cover;
  min-width: 120px;
  height: 120px;
  border-radius: 8px;
}

.news-ltr-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.news-ltr-card {
  position: relative;
  width: 400px;
}

.nws-ltr-card-img {
  width: 100%;
  height: 320px;
}
.ramadan-zakat-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
  justify-content: center;
}
.ramadan-zakat-card {
  position: relative;
  width: 365px;
}
.ramadan-zakat-card-img {
  width: 100%;
}
.btn-abs {
  display: block;
  text-align: center;
  /* position: absolute; */
  background-color: #002866;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
}

.btn-abs:hover {
  color: white;
}

.ltr-card-txt-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ltr-card-txt {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.ltr-html-render {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ltr-html-render-blog {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
}
.ltr-html-render-blog-ramadan {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}

.signin-button:hover {
  color: var(--primary) !important;
  border: 1px solid var(--secondary) !important;
  background-color: var(--secondary) !important;
}

.pagination > li > a,
.pagination > li:first-child > a {
  margin-right: 10px !important;
}

.active > .page-link,
.page-link.active {
  color: var(--bs-pagination-active-color);
  background-color: var(--primary);
  border-color: var(--primary);
}

.page-link:hover {
  background-color: var(--secondary);
  border-color: var(--secondary);
}
.help-us-heading {
  font-size: 35px;
}
.divider-verticle {
  border-left: 3px solid #002866;
  height: 200px;
  margin: auto 25px;
}
.l-border {
  border-left: 3px solid #002866;
}
.pdf-container {
  position: relative;
}

.tooltip {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.pdf-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
}
.document-tab-images img {
  width: 200px;
}
.swal-button--confirm {
  background-color: var(--primary) !important;
  color: white !important;
  border: 1px solid var(--primary);
}
.swal-button--confirm:hover {
  background-color: var(--secondary) !important;
  color: var(--primary) !important;
  border: 1px solid var(--secondary);
}
.swal-button--danger {
  background-color: var(--primary) !important;
  color: white !important;
  border: 1px solid var(--primary);
}
.swal-button--cancel {
  background-color: transparent !important;
  color: var(--primary) !important;
  border: 2px solid var(--primary) !important;
}
.swal-button--cancel:hover {
  background-color: var(--secondary) !important;
  color: var(--primary) !important;
  border: 1px solid var(--secondary) !important;
}
.fun-donate-btn1 {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 8px;
  font-weight: 600;
  color: #002866 !important;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  padding: 8px 20px;
  cursor: pointer;
  border: 2px solid #002866;
}
.fun-donate-btn2 {
  border-radius: 8px;
  font-weight: 600;
  color: #fff;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  padding: 8px 20px;
  cursor: pointer;
  border: 1px solid #fff;
}
.fun-donate-btn1:hover {
  background-color: #f9cb40 !important;
  color: #002866 !important;
  border-color: #f9cb40 !important;
}
.fun-donate-btn2:hover {
  background-color: #f9cb40 !important;
  color: #002866 !important;
  border-color: #f9cb40 !important;
}
.btn__primary1 {
  background: #002866;
  color: white;
  border-radius: 8px;
}
.buttonPrimary1 {
  transition: all 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight: 600;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  align-items: center;
  border: 0;
}
.buttonPrimary1:hover {
  color: #002866 !important;
  background: #f9cb40;
}
.story-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-align: left;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.story-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-align: left;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.action-button-view {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 8px;
  font-weight: 600;
  color: #002866 !important;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  padding: 4px 8px;
  cursor: pointer;
  border: 1px solid #002866;
}
.action-button-edit {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 8px;
  font-weight: 600;
  color: var(--secondary) !important;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  padding: 4px 8px;
  cursor: pointer;
  border: 1px solid var(--secondary);
}
.action-button-withdraw {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 8px;
  font-weight: 600;
  color: #31ce36 !important;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  padding: 4px 10px;
  cursor: pointer;
  border: 1px solid #31ce36;
}
.action-button-delete {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 8px;
  font-weight: 600;
  color: #dc3545 !important;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  padding: 4px 8px;
  cursor: pointer;
  border: 1px solid #dc3545;
}
.action-button-view:hover {
  background-color: #002866 !important;
  color: white !important;
  border-color: #002866 !important;
}
.action-button-edit:hover {
  background-color: #f9cb40 !important;
  color: white !important;
  border-color: #f9cb40 !important;
}
.action-button-withdraw:hover {
  background-color: #31ce36 !important;
  color: white !important;
  border-color: #31ce36 !important;
}
.action-button-delete:hover {
  background-color: #dc3545 !important;
  color: white !important;
  border-color: #dc3545 !important;
}
.hidden-column {
  display: none;
}
.fund-card-amount {
  border: 2px solid var(--dark);
  border-radius: 8px;
}
.verification-step-card {
  border-radius: 8px;
}
.step-image {
  background-position: 0 0;
  text-align: center;
  margin: 0 auto;
  background-repeat: no-repeat;
}
.step-image img {
  background-position: 198px -13px;

  width: 100%;
  text-align: center;
}
.fund-card-amount-detail {
  border: 2px solid var(--primary);
  color: var(--primary);
  border-radius: 8px;
  font-size: 20px;
  line-height: 35px;
}
.ant-table {
  font-size: 18px !important;
  color: black !important;
}
.top-collected-amount {
  text-shadow: rgb(0, 0, 0) 5px 5px 5px;
  width: 100%;
  position: relative;
  color: var(--white) !important;
}
.confirmation-dialog {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 999; /* Ensure it's above other content */
}

.confirmation-dialog button {
  margin: 5px;
}
.beta-tag {
  top: 10px;
  left: 10px;
  z-index: 999;
  background-color: var(--secondary);
  border-radius: 6px;
  padding: 2px 4px;
  color: var(--primary);
  margin-left: 20px;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.whatsapp-chat {
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 9999999;
  border-radius: 30%;
  overflow: hidden;
}
.modal-alignment,
.modal.show .modal-dialog {
  padding: 40px;
}
.modal-backdrop.show {
  width: 100%;
  height: 100%;
}
.display-payment-gatway-icons {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1680px) {
  body {
    zoom: 0.9;
  }
}

@media (max-width: 1366px) {
  body {
    zoom: 0.8;
  }

  .mobile__menu {
    padding: 0 20px;
    background: #fff;
  }
  .volunteer-section {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 80px;
  }
}

@media (max-width: 1280px) {
  .about-card {
    width: 100%;
  }

  .about-card-sec {
    text-align: center;
  }
  .step-card-body {
    width: 100%;
    position: relative;
    bottom: 0;
    padding: 0px 12px;
    right: 0;
    height: 380px;
  }
  .display-payment-gatway-icons {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1200px) {
  .header__menu {
    list-style: none;
    display: block;
    align-items: center;
    justify-content: end;
    width: 100%;
  }

  .header__menu li {
    padding: 8px 4px;
  }
}

@media (max-width: 1100px) {
  .grid-container {
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  .mobile-view {
    margin-top: 1rem;
  }

  /* .hero-section {
    left: 50%;
  } */
}

@media (max-width: 1040px) {
  .volunteer-section {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
}

@media (max-width: 992px) {
  .step-arrow,
  .step-arrow-second {
    display: none;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (max-width: 991px) {
  .banner-heading {
    font-size: 48px !important;
    color: #fff !important;
    opacity: 0.9 !important;
  }

  .banner-desc {
    font-size: 22px !important;
    color: #fff !important;
    opacity: 0.9 !important;
    font-weight: 500 !important;
  }
}

@media (max-width: 800px) {
  .grid-container {
    grid-template-columns: 1fr 1fr !important;
  }

  .mobile-view {
    margin-top: 1rem;
  }
}

@media (max-width: 792px) {
  .banner-title {
    width: 100%;
  }
  .testi-flex {
    flex-direction: column;
  }
  .l-border {
    border-left: none;
  }
}

@media (max-width: 767px) {
  .owl-carousel .owl-nav {
    display: none !important;
  }

  .payment-check {
    width: 100%;
  }

  .owl-carousel .owl-nav {
    display: none !important;
  }

  .payment-check {
    width: 100%;
  }

  .step-card {
    width: 100%;
  }

  .steps-li {
    display: flex;
    justify-content: center;
  }

  .step-border {
    display: inline-block;
    padding: 10px 20px;
  }

  .step-flex {
    flex-direction: column;
  }

  .step-card-first {
    width: 100%;
  }

  .step-card {
    width: 100%;
  }
  .dashboard-text-center {
    text-align: center;
  }
  .step-card-body {
    width: 100%;
    position: relative;
    bottom: 0;
    padding: 0px 12px;
    right: 0;
    height: 475px;
  }
  .step-card-design {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    border-radius: 16px !important;
    width: 75%;
    background-color: #ffffff;
    margin-bottom: 30px;
    box-shadow: 2px 6px 15px 0px rgba(69, 65, 78, 0.1);
    border: 0px;
  }
  .verification-step-main {
    display: flex;
    gap: 32px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .background-image {
    display: none;
  }
}

@media (max-width: 600px) {
  .hero-section {
    left: 50%;
  }
  .cnic-img .ant-upload-list-picture-card,
  .cnic-img .ant-upload-list-item-container {
    border-radius: 12px !important;
    position: relative;
    margin: 0 auto !important;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: auto !important;
    width: auto !important;
    display: inline-flex !important;
    background-color: transparent !important;
    color: var(--primary);
    margin-right: 20px !important;
  }
}

@media (max-width: 575px) {
  .donatenow__modal .modal-dialog {
    max-width: 94%;
  }

  .offcanvas.offcanvas-start {
    width: 100%;
  }

  .min__height--mobile {
    min-height: 450px;
  }

  .section__contact {
    padding-bottom: 0%;
  }

  .section__contact h3,
  .section__contact h5 {
    color: var(--white);
    margin-bottom: 16px;
  }

  .banner-title {
    margin-top: 150px !important;
    line-height: 70px;
    font-size: 48px !important;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
  }

  .banner-heading {
    font-size: 40px !important;
    color: #fff !important;
    opacity: 0.9 !important;
  }

  .banner-desc {
    font-size: 18px !important;
    color: #fff !important;
    opacity: 0.9 !important;
    font-weight: 500 !important;
  }

  .banner-title-bottom {
    /* margin-top: 170px !important; */
    /* line-height: 70px; */
    letter-spacing: 1px;
    font-size: 35px !important;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
  }

  .section__form--contact .section--gray {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }

  .mobile-veiw-alignment {
    direction: none;
    text-align: center;
  }

  .wrapper .section__form--contact + .section__footer {
    margin-top: 0%;
  }

  .mobile-view {
    justify-content: center;
    display: flex;
  }

  .mobile-view-center {
    justify-content: center;
    display: grid;
  }

  .header__menu li {
    padding: 8px 4px;
  }

  .mobile__menu {
    padding: 0 20px;
    background: #fff;
  }

  .header__menu {
    list-style: none;
    display: block;
    width: 100%;
    padding-left: 0;
  }

  .donation__category .paddingLeft__desktop--40 {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  .volunteer__form {
    margin-top: 0;
  }

  .navbar__logo {
    width: 150px;
  }

  .banner,
  .donation--bg {
    text-align: center;
    margin-top: 61px;
  }

  .mobile__navbar {
    background: #fff;
    padding-bottom: 20px;
  }

  .banner h2 {
    font-size: 20px;
  }

  .banner p {
    font-size: 16px;
    margin-bottom: 40px;
  }

  .list-inline-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0 !important;
  }

  .list-inline-item button {
    min-width: 180px;
    margin: 10px 10px 10px 10px !important;
  }

  h1,
  .h1 {
    font-size: 42px;
    margin-bottom: 30px;
  }

  .padding__left-25 {
    padding-left: 13px;
  }

  .header__top {
    height: 70px;
  }

  .counters {
    text-align: center;
    padding-right: 0;
    margin-bottom: 30px;
  }

  .counter__divider {
    margin: 2rem auto 2rem auto;
  }

  .mobile__text--center {
    text-align: center;
  }

  h2,
  .h2 {
    font-size: 34px;
    margin-bottom: 30px;
  }

  .counterSection {
    padding: 105px 0px 225px 0px;
  }

  .become__sponsor .become__sponsor--content {
    padding: 50px 15px 50px 15px;
    text-align: center;
  }

  .helpingSection .mobile__text--center {
    text-align: center;
  }

  .section {
    padding: 70px 0;
  }

  .projectSection .container {
    max-width: 100% !important;
    margin: 0 auto;
  }

  .projectItem__body {
    padding: 30px;
  }

  .event.has-thumbnail {
    margin-bottom: 120px;
  }

  .event__thumbnail,
  .event__content-header,
  .event__address,
  .event__thumbnail-wrap {
    width: 100%;
  }

  .event__address:before {
    width: 0;
  }

  .event__content-header {
    padding: 20px 20px 0 20px;
  }

  .event__address {
    padding: 0px 20px 20px 20px;
  }

  .event__address-tile {
    display: none;
  }

  .giveTotals__header {
    padding: 30px 16px;
  }

  .donateSection {
    margin-top: -50%;
  }

  .mobile-view {
    margin-top: 1rem;
  }

  .giveTotals__header__title__desc {
    margin-bottom: 8px;
  }

  .hero-section {
    margin: 0;
  }

  .raised__income,
  .raised__goal {
    margin: 0;
  }

  .giveTotals__input .form-control::placeholder {
    color: var(--primary) !important;
  }

  .income {
    font-size: 20px;
  }

  .giveTotals__header__title {
    font-size: 28px;
  }

  .giveTotals {
    background-color: transparent;
  }

  .event__thumbnail {
    padding-bottom: 66%;
  }

  .event.has-thumbnail,
  .event__content {
    display: block;
  }

  .giveTotals__form {
    padding: 30px 16px;
  }

  .mobile__block {
    display: block;
  }

  .giveTotals__form__title {
    font-size: 24px;
  }

  .giveTotals__form__desc {
    margin-bottom: 20px;
  }

  .giveTotals__input .input-group-text,
  .giveTotals__input .form-control {
    padding: 6px 20px;
  }

  .giveTotals__checks .btn {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 12px;
  }

  .custom__amount {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .backgroundImage__thank {
    padding: 15px 0;
  }

  .call__img {
    margin-bottom: 30px;
  }

  .call__img svg {
    width: 32px;
    height: 32px;
  }

  .blog__card .blog__content {
    padding: 15px 20px;
  }

  .mobile__donate {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 99;
  }

  .footer__bottom {
    margin-bottom: 60px;
  }

  .mobile__donate button {
    border-radius: 0;
  }

  .donation--bg .donation__badge {
    font-size: 18px;
    white-space: nowrap;
  }

  .fundraiser--bg .content div {
    width: 100% !important;
  }
  .help-us-heading {
    font-size: 30px !important;
  }
  .step-card-body {
    width: 100%;
    position: relative;
    bottom: 0;
    padding: 0px 12px;
    right: 0;
    height: 510px;
  }
}
@media (max-width: 500px) {
  .fundRaisingSection {
    padding: 0px 100px;
  }
  .step-card-body {
    width: 100%;
    position: relative;
    bottom: 0;
    padding: 0px 12px;
    right: 0;
    height: 460px;
  }
}
@media (max-width: 450px) {
  .fundRaisingSection {
    padding: 0px 50px;
  }
  .step-card-body {
    width: 100%;
    position: relative;
    bottom: 0;
    padding: 0px 12px;
    right: 0;
    height: 440px;
  }
}
@media (max-width: 400px) {
  .button-responsive {
    display: flex;
    flex-direction: column;
  }
  .step-card-body {
    width: 100%;
    position: relative;
    bottom: 0;
    padding: 0px 12px;
    right: 0;
    height: 400px;
  }
}
@media (max-width: 365px) {
  .li-fundraiser-card {
    min-width: 100%;
  }
  .fundRaisingSection {
    padding: 0px 30px;
  }
  .grid-container {
    grid-template-columns: 1fr !important;
  }
  .step-card-body {
    width: 100%;
    position: relative;
    bottom: 0;
    padding: 0px 12px;
    right: 0;
    height: 390px;
  }
}
