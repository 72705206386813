.banner-section{
    position: relative;
}
.banner-img{
    width: 100%;
    height: 65vh;
    background-attachment: fixed;
    z-index: 20;
    /* background-image: url("../img/bg.png"); */
    background-size: cover;
    background-position: 50% 0%;
    background-repeat: no-repeat;
}
.shadow-box{
    box-shadow: 0 -70px 70px #0003, 0 30px 60px #0000001a;
    padding: 0px 40px 40px 40px;
    width: 803px;
    bottom: -100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 8px;
    text-align: center;
    color: #444;
    position: absolute;
    z-index: 1;
}
.title-t{

    color: var(--primary);
    font-weight: 700;
    font-size: 36px;
    /* line-height: 45px; */
    /* margin: 10px 0; */
    text-align: center;
}
.subtitle{
    margin: auto;
    width: 703px;
    font-size: 17px;
    line-height: 24px;
}
.subtitle-t{
    /* width: 917px; */
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    margin: 10px auto 0;
}
.dna-img{
    width: 800px;
    margin: 0 auto;
    display: block;
}
.title-pad-40{
    font-size: 26px;
    line-height: 45px;
    font-weight: 700;
    text-align: center;
    color: var(--primary);
}
.know-more-bg{
    position: relative;
    background-repeat: no-repeat;
}
.shadow-box-more{
    box-shadow: 0 -70px 70px #0003, 0 30px 60px #0000001a;
    padding: 0px 40px 40px 40px;
    width: 803px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 8px;
    text-align: center;
    color: #444;
    position: absolute;
    z-index: 1;
}
.banner-img-bg{
    height: 700px;
    width: 100%;
    object-fit: cover;
}
.send-card{
    width: 847px;
    padding: 20px 40px 40px 40px;
    position: absolute;
    top: 50%;
    background-color: white;
    transform: translate(-50%,-50%);
    left: 50%;
    box-shadow: 0 9px 46px #0000001f, 0 24px 38px #00000024, 0 11px 15px #0003;
    border-radius: 8px;
    text-align: center;
}
.wait{
    font-size: 26px;
    line-height: 33px;
    font-weight: 700;
    color: var(--primary);
}
.img-wrap{
    margin-bottom: 20px;
    box-sizing: border-box;
    display: block;

}
.blog-img{
    /* height: 200px; */
    object-fit: cover;
    max-width: 100%;
    
}